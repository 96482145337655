<template>
    <div class="content">
      
        <el-row class="bgW">
            <el-col :span="22">
            <!--检索条件-->
            <el-form class="search" :inline="true" size="mini" :model="search">
                <el-form-item label="调查时间">
                    <el-date-picker
                        v-model="search.create_time_start"
                        type="date"
                        class="w130"
                        placeholder="开始日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <el-date-picker
                        v-model="search.create_time_end"
                        type="date"
                        class="w130"
                        placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                
                <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
                    <el-select
                        v-model="search.shop_id"
                        clearable
                        remote
                        filterable
                        :remote-method="remoteMethod"
                        placeholder="请输入"
                        class="w120"
                    >
                    <el-option
                        v-for="item in options.shop_id"
                        :key="item.shopId"
                        :label="item.shopName"
                        :value="item.shopId"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="问卷分类">
                    <el-select v-model="search.wenjuan_id" clearable class="w120">
                        <el-option
                        v-for="item in options.wenjuan_type"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </el-form-item>
    
                
            </el-form>
            </el-col>
            <el-col :span="2" style="text-align: right;">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
            </el-col>
        </el-row>
  
        <div class="accountCon">
            <div class="btnBox">
                <el-button
                    type="primary"
                    class="mb10"
                    size="mini"
                    v-if="!userInfo.shop_id"
                    @click="exportData('export')"
                >导出数据</el-button>
            </div>
            <!--列表-->
            <el-row>
                <el-col :span="24">
                    <el-table
                        :data="list"
                        size="small"
                        border
                        :cell-style="$style.cellStyle"
                        :header-cell-style="$style.rowClass"
                        @selection-change="handleSelectionChange"
                        
                    >
                        <ElTableColumn label="姓名" prop="customer_id" width="200"  fixed/>
                        <ElTableColumn label="手机" width="200" prop="telephone" />
                        <ElTableColumn label="门店/门诊" prop="shop_id" width="200" />
                        
                        <ElTableColumn v-for="item in questionList" :label="item.question_describe" width="100%" >
                            <template slot-scope="{ row }" >
                                <div>{{ row[item.id] }}</div>
                            </template>
                        </ElTableColumn>
                        <ElTableColumn label="总分" prop="scoreAmount" width="100" />
                        <ElTableColumn label="时间" prop="create_time" width="180" />
                    </el-table>

                    
                    <pagination
                        class="page tc mt10"
                        :total="page.total"
                        :page.sync="page.page"
                        :limit.sync="page.limit"
                        @pagination="getList"
                    />
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import pagination from "@/components/Pagination";
import {
    clientListLS,
    ssettingList,
    statusSetting,
    getGradeList,
    clientExport
} from "@/api/client";
import { getShopList } from "@/api/shop";
import { manyiduList,getAnswers } from "@/api/sys";
  
class Search {
    create_time_start = "";
    create_time_end = "";
    shop_id = "";
    feed_type = "";
    wenjuan_id = 2;
}

class Options {
    
    shop_id = [];
    wenjuan_type = [
        {id:2, name:'成交1个月'},
        {id:3, name:'成交3个月'},
        {id:4, name:'成交5个月'},
        {id:5, name:'成交10个月'},
    ];
    
}

class Page {
    total = 0;
    page = 1;
    limit = 10;
}
export default {
    name: "AlarmClient",
    components: {
        pagination
    },
    data() {
        return {
            search: new Search(),
            options: new Options(),
            page: new Page(),
            list: [],
            idList: "",
            wenjuanID: '2',
            questionList: [],
        };
    },
    filters: {
        statusFilter(val) {
            return ["启用", "禁用"][+val];
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    mounted() {
        // this.getList();
        this.getOptions();
        // this.getQuestionnaire();
        this.getList();
    },
    methods: {
        getOptions() {
            ssettingList({ page: 1, limit: 100000 }).then(res => {
                let that = this;
                res.list.forEach(function(item) {
                    if (item.category_id == 14) {
                        that.options.feed_type.push(item);
                    } 
                });
            });

            
        },

        getQuestionnaire(){
            getAnswers({ wenjuanID: this.search.wenjuan_id }).then(res => {
                console.log(res.data.list);
                this.questionList = res.data.list;
            });
        },
        
        // 获取列表
        getList(option) {
            this.getQuestionnaire();
            // return;
            // return;
            if (option === "search") this.page = new Page();
            manyiduList({ ...this.search, ...this.page, is_export:0, wenjuanID: this.search.wenjuan_id }).then(
                
                res => {
                    console.log(res);
                    // return;
                    this.list = res.data.list;
                    this.page.total = res.data.dataCount;
                }
            );
        },
  
        // 导出数据
        exportData(option) {
            

            if (option === "export") this.page = new Page();
            manyiduList({ ...this.search, ...this.page, is_export:1, wenjuanID:2 }).then(
                res => {
                    // console.log(res);
                    window.open(res.data.url);
                }
            );
        },
  
        // 搜索门店
        remoteMethod(e) {
            getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
                this.options.shop_id = res.list;
            });
        },

        // 点击按钮详情
        tapBtn(routerName, id) {
            const query = { id: id, activeName: "first" };
            // this.$router.push({ name: routerName, query });
            let route = this.$router.resolve({ name: routerName, query });
            window.open(route.href, '_blank');
        },

        // 提示
        hint(text) {
            this.$confirm(`${text},是否确定？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.getExport();
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },

        // 导出
        getExport() {
            clientExport({ id: this.idList, type: 5 }).then(res => {
                window.open(res.data.url);
            });
        },

        // 改变表格选中状态
        handleSelectionChange(val) {
            this.idList = val.map(i => {
                return i.id;
            });
        }
    }
};
</script>
  
<style lang="scss" scoped>
.mainColor {
    color: #2dc0a3;
}
.content {
    height: calc(100% - 56px);
    overflow-y: auto;
}
.bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
}
  
.el-form-item {
    margin: 5px 10px 3px 0;
}
.accountCon {
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox {
        display: flex;
    }
}
.describe{
    padding-left: 10px;
    .describeText{
        color: #666;
    }
    .dataMsg {
        line-height: 30px;
        span {
            margin-left: 0px;
            font-weight: bold;
        }
    }
}
  
</style>
  